import { authenticate, AuthenticateReturn, GrantType } from '@commercelayer/js-auth';
import type {
  Address,
  BraintreePayment,
  Bundle,
  CommerceLayerClient,
  Customer,
  CustomerAddress,
  LineItem,
  LineItemCreate,
  LineItemOption,
  Order,
  OrderCreate,
  OrderUpdate,
  QueryPageSize,
  Shipment,
  Sku,
} from '@commercelayer/sdk';
import CommerceLayer, { CommerceLayerStatic } from '@commercelayer/sdk';
import addresses from '@data/addresses.json';
import { Locales } from '@model/common';
import { ProductAndBundleDataType } from '@model/product';
import { AddressFormData, BillingAddressFormData } from '@src/types/address';
import { api } from '@utils/config';
import {
  deleteCartIdSession,
  deleteUserSession,
  getCartIdSession,
  getUserSession,
  setCartIdSession,
  setUserSession,
  UserData,
} from '@utils/session-storage';
import { AxiosError } from 'axios';
import { trackAddToCart, trackRemoveFromCart, trackSignUp } from './utils/gtmUtils';

const defaultHeaders = {
  Accept: 'application/vnd.api+json',
  'Content-Type': 'application/vnd.api+json',
};

const SRV_URL = '/api';

export type AddressType = 'billing' | 'shipping';

type ConstructorType = {
  marketId: string;
  countryCode: string;
  endpoint: string;
  clientId: string;
};

export class AppCommerceLayerClient {
  private marketId = '';
  private countryCode = '';
  private keys: { endpoint: string; clientId: string };
  private order: Order;
  private token: AuthenticateReturn<GrantType>;
  private customer: Customer;
  private commerceLayerSdk: CommerceLayerClient;
  private apiClientUrl: string = '';

  constructor(params: ConstructorType) {
    const { marketId, countryCode, endpoint, clientId } = params;
    this.marketId = marketId;
    this.countryCode = countryCode;
    this.keys = { endpoint, clientId };

    if (this.keys) {
      this.apiClientUrl = this.keys.endpoint + '/api';
    }
  }

  public async init() {
    if (!this.marketId) {
      return;
    }
    const { clientId, endpoint } = this.keys;
    const user = getUserSession();
    if (user) {
      await this.loginFromSession(user);
    } else {
      try {
        const auth = await authenticate('client_credentials', {
          clientId: clientId,
          scope: `market:id:${this.marketId}`,
        });
        if (!auth?.accessToken) {
          throw { message: 'TOKEN_ERROR', auth: auth };
        }
        this.commerceLayerSdk = CommerceLayer({
          organization: this.organizationFromEndPoint(endpoint),
          accessToken: auth.accessToken,
        });
        this.token = auth;
      } catch (error) {
        this.logError('init ERROR', error);
        throw error;
      }
    }
  }

  public async sleep(delay: number) {
    return new Promise((resolve) => setTimeout(resolve, delay));
  }

  public getOrderId() {
    return getCartIdSession();
  }

  public cleanup() {
    deleteCartIdSession();
    this.order = undefined;
    deleteUserSession();
  }

  private organizationFromEndPoint(endpoint: string) {
    return endpoint.split('://')[1].split('.')[0];
  }

  // PRODUCT

  public async getProductList(skuCode: string[]): Promise<Sku[]> {
    const skus: Sku[] = [];
    try {
      let nextPage = true;
      let currentPage = 1;
      while (nextPage) {
        const response = await this.commerceLayerSdk.skus.list({
          filters: { code_matches_any: skuCode.join(',') },
          include: ['sku_options', 'prices', 'stock_items', 'stock_items.reserved_stock'],
          pageNumber: currentPage,
          pageSize: 25,
        });

        skus.push(...response);

        currentPage++;

        nextPage = currentPage <= response.meta.pageCount;
      }
    } catch (error) {
      this.logError('getProductList', error);
    }
    return this.calculateSkusInventoryFromStockItems(skus);
  }

  public async getProduct(skuCode: string): Promise<Sku | null> {
    if (!skuCode) {
      return null;
    }
    try {
      const skus = await this.commerceLayerSdk.skus.list({
        filters: { code_matches_any: skuCode },
      });
      if (!skus?.first()) {
        return null;
      }

      //skus.list doesn't return inventory object so we need to do a skus.retrieve call
      return await this.commerceLayerSdk.skus.retrieve(skus.first()!.id, {
        include: ['sku_options', 'prices', 'stock_items', 'stock_items.reserved_stock'],
      });
    } catch (error) {
      this.logError('getProduct', error);
    }
  }

  // BUNDLE

  public async getBundleList(skuCode: string[]): Promise<Bundle[]> {
    const bundles: Bundle[] = [];
    try {
      let nextPage = true;
      let currentPage = 1;
      while (nextPage) {
        const response = await this.commerceLayerSdk.bundles.list({
          filters: { code_matches_any: skuCode.join(',') },
          include: [
            'skus',
            'skus.stock_items',
            'sku_list.sku_list_items',
            'skus.stock_items.reserved_stock',
          ],
          pageNumber: currentPage,
          pageSize: 25,
        });
        bundles.push(...response);
        currentPage++;

        nextPage = currentPage <= response.meta.pageCount;
      }
    } catch (error) {
      this.logError('getBundleList', error);
    }
    return bundles.map((bundle: Bundle) => {
      bundle.skus = this.calculateSkusInventoryFromStockItems(bundle.skus);
      return bundle;
    });
  }

  public async getBundle(skuCode: string) {
    if (!skuCode) {
      return null;
    }
    try {
      const bundles = await this.commerceLayerSdk.bundles.list({
        filters: { code_matches_any: skuCode },
      });
      if (!bundles?.first()) {
        return null;
      }

      //bundles.list doesn't return inventory object so we need to do a bundles.retrieve call
      return await this.commerceLayerSdk.bundles.retrieve(bundles.first()!.id, {
        include: [
          'skus',
          'skus.stock_items',
          'sku_list.sku_list_items',
          'skus.stock_items.reserved_stock',
        ],
      });
    } catch (error) {
      this.logError('getBundle', error);
    }
  }

  private calculateSkusInventoryFromStockItems = (skus: Sku[]) => {
    return skus.map((sku) => {
      const quantity = this.sumStockItemsQuantity(sku);
      sku.inventory = { ...sku.inventory, quantity, available: quantity > 0 };
      return sku;
    });
  };

  private sumStockItemsQuantity = (sku: Sku) =>
    (sku.stock_items || []).reduce((qty, stockItem) => {
      return (qty += (stockItem?.quantity || 0) - (stockItem.reserved_stock?.quantity || 0));
    }, 0);

  public getUserSalesChannelToken = async (username: string, password: string) => {
    const { clientId } = this.keys;
    const scope = `market:id:${this.marketId}`;
    return await authenticate('password', {
      clientId: clientId,
      scope: scope,
      username: username,
      password: password,
    });
  };

  private async loginFromSession(user: UserData) {
    try {
      //reload user by accesstoken saved
      const token = await this.renewToken(user.refreshToken);
      this.token = token;
      const userData: UserData = {
        email: user.email,
        owner_id: user.owner_id,
        accessToken: token.accessToken,
        refreshToken: token.refreshToken,
      };
      await this.postLoginOperations(userData);
    } catch (error) {
      this.logError('loginFromSession ERROR', error);
      this.checkError(error);
    }
  }

  public async login(user: { email: string; password: string }) {
    try {
      const { email, password } = user;
      const token = await this.getUserSalesChannelToken(email, password);
      if (token.errors) {
        throw 'WRONG_CREDENTIALS';
      }
      this.token = token;

      const userData: UserData = {
        email: user.email,
        owner_id: token.ownerId,
        accessToken: token.accessToken,
        refreshToken: token.refreshToken,
      };

      await this.postLoginOperations(userData);
    } catch (error) {
      if (error != 'WRONG_CREDENTIALS') {
        this.logError('LOGIN ERROR', error);
      }
      throw error;
    }
  }

  private async postLoginOperations(userData: UserData) {
    const { endpoint } = this.keys;
    this.commerceLayerSdk = CommerceLayer({
      organization: this.organizationFromEndPoint(endpoint),
      accessToken: this.token.accessToken,
    });

    let customer = null;
    if (userData.owner_id) {
      customer = await this.commerceLayerSdk.customers.retrieve(userData.owner_id, {
        include: ['customer_addresses.address'],
      });
      this.customer = customer;
      setUserSession(userData);
    }

    if (customer && customer.customer_addresses) {
      const addresses = customer.customer_addresses.filter(
        (a) => a.address?.country_code === this.countryCode
      );
      if (addresses.length > 0) {
        await this.migrateAddressesIfNeeded(addresses);
      }
      this.removeInvalidAddress(customer.customer_addresses);
    }

    if (customer) {
      if (!this.order) {
        await this.getOrder();
      }
      await this.addCustomerToOrder();
    }
  }

  private async removeInvalidAddress(customerAddresses: CustomerAddress[]) {
    customerAddresses.forEach(async (customerAddress) => {
      // state_code for US country must be 2 char lenght
      if (
        customerAddress.address.country_code == 'US' &&
        customerAddress.address.state_code.length != 2
      ) {
        await this.commerceLayerSdk.customer_addresses.delete(customerAddress.id);
        await this.commerceLayerSdk.addresses.delete(customerAddress.address.id);
      }
      if (customerAddress.address?.metadata?.type == 'test') {
        //Removing fake customer addresses
        await this.commerceLayerSdk.customer_addresses.delete(customerAddress.id);
      }
    });
  }

  private async migrateAddressesIfNeeded(customerAddresses: CustomerAddress[]) {
    const oldVersionAddresses = this.extractOldAddresses(customerAddresses);
    if (oldVersionAddresses.length > 0) {
      for await (const customerAddress of oldVersionAddresses) {
        if (customerAddress.address) {
          const addressType: AddressType = customerAddress.address.metadata?.fiscalCode
            ? 'billing'
            : 'shipping';
          await this.updateAddress(customerAddress.address, addressType);
        }
      }
    }
  }

  private extractOldAddresses(customerAddresses: CustomerAddress[]) {
    return customerAddresses.filter(
      (customerAddress) =>
        !(
          customerAddress.address?.metadata &&
          'isBilling' in customerAddress.address?.metadata &&
          'isShipping' in customerAddress.address?.metadata
        )
    );
  }

  public async register(
    user: { email: string; password: string },
    metadata: string,
    subscribeConsent = false
  ) {
    const { email, password } = user;
    return await this.doRegister({ email, password, metadata, subscribeConsent });
  }

  private async doRegister(attributes: {
    email: string;
    password: string;
    metadata: string;
    subscribeConsent: boolean;
  }) {
    const url = `${SRV_URL}/signup`;
    try {
      const res = await api.post(url, attributes, { headers: this.headers });
      trackSignUp();
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  public async logout() {
    this.cleanup();
    this.customer = undefined;
    await this.init();
    await this.createOrder();
  }

  private async renewToken(refreshToken: string) {
    const { clientId } = this.keys;
    const scope = `market:id:${this.marketId}`;
    return await authenticate('refresh_token', {
      clientId: clientId,
      scope: scope,
      refreshToken: refreshToken,
    });
  }

  public countOrderProductsQuantity(order: Order): number {
    const lineItems = order?.line_items;
    if (!lineItems) {
      return 0;
    }
    return lineItems
      .filter((i) => i.item_type === 'skus' || i.item_type === 'bundles')
      .map((lineItem) => lineItem.quantity)
      .reduce((acc, value) => acc + value, 0);
  }

  public async addToCart(
    product: ProductAndBundleDataType,
    indexGtm: number,
    incQuantity = 1,
    itemListName: string
  ) {
    let result = {
      error: undefined as unknown,
      success: true,
      image: product.image,
      name: product.name,
      incQuantity,
    };

    const { skuCode, name, image, option, maxQuantity, levels, url } = product;
    let order = await this.getOrder();
    if (!order) {
      order = await this.createOrder();
    }
    const lineItems = order?.line_items;
    const existingLineItem = lineItems?.find((li) => li.sku_code === skuCode);
    if (existingLineItem) {
      const quantity = incQuantity + existingLineItem.quantity!;
      try {
        await this.updateLineItemQuantity(existingLineItem, quantity, indexGtm, itemListName);
      } catch (error) {
        this.logError('CART UPDATE error', error);
        result = { ...result, error, success: false };
      }
    } else {
      try {
        const quantity = incQuantity;
        const lineItem = await this.commerceLayerSdk.line_items.create({
          order: order,
          quantity,
          sku_code: skuCode,
          name,
          image_url: image.src,
          metadata: { maxQuantity, levels, url },
        });
        if (option) {
          await this.commerceLayerSdk.line_item_options.create({
            name: option.name,
            quantity,
            options: { description: option.description },
            line_item: this.commerceLayerSdk.line_items.relationship(lineItem.id),
            sku_option: this.commerceLayerSdk.sku_options.relationship(option.id),
          });
          // force refresh order for webhook shipping and taxes calculator
          await this.refreshOrder(order.id);
        }

        trackAddToCart(lineItem, incQuantity, indexGtm, itemListName);
      } catch (error) {
        this.logError('CART CREATE error', error);
        result = { ...result, error, success: false };
      }
    }
    return result;
  }

  public async addBundleToCart(
    product: ProductAndBundleDataType,
    indexGtm: number,
    incQuantity = 1,
    itemListName: string
  ) {
    let result = {
      error: undefined as unknown,
      success: true,
      image: product.image,
      name: product.name,
      incQuantity,
    };
    const { skuCode, name, image, maxQuantity, levels, id, url, metadata } = product;
    let order = await this.getOrder();
    if (!order) {
      order = await this.createOrder();
    }
    const existingLineItem = order?.line_items?.find((li) =>
      li.sku_code ? li.sku_code === skuCode : li.bundle_code === skuCode
    );

    if (existingLineItem) {
      const quantity = incQuantity + existingLineItem.quantity!;
      try {
        await this.updateLineItemQuantity(existingLineItem, quantity, indexGtm, itemListName);
      } catch (error) {
        this.logError('addBundleToCart update', error);
        result = { ...result, error, success: false };
      }
    } else {
      const quantity = incQuantity;
      try {
        const createObject: LineItemCreate = {
          order: this.commerceLayerSdk.orders.relationship(order.id),
          item: this.commerceLayerSdk.bundles.relationship(id),
          quantity: quantity,
          name,
          bundle_code: skuCode,
          image_url: image.src || '',
          metadata: {
            ...metadata,
            maxQuantity: maxQuantity || '',
            levels: levels || [],
            url: url || '',
          },
        };

        const lineItem = await this.commerceLayerSdk.line_items.create(createObject);

        lineItem.sku_code = lineItem.bundle_code;

        trackAddToCart(lineItem, quantity, indexGtm, itemListName);
      } catch (error) {
        this.logError('addBundleToCart create', error);
        result = { ...result, error, success: false };
      }
    }
    return result;
  }

  public async removeLineItem(lineItem: LineItem) {
    try {
      await this.commerceLayerSdk.line_items.delete(lineItem.id);
      trackRemoveFromCart(lineItem);
    } catch (error) {
      this.logError('removeLineItem error', error);
    }
  }

  public async checkEtagUpdate(currentOrderEtag?: string): Promise<Order | undefined> {
    let retries = 0;
    while (retries < 10) {
      const order = await this.getOrder();
      const etag = order?.metadata?.etag;
      if (etag && etag !== currentOrderEtag) {
        return order;
      }
      retries += 1;
      await this.sleep(1000);
    }
  }

  public async refreshOrder(orderId: string): Promise<Order> {
    return await this.commerceLayerSdk.orders._refresh(orderId);
  }

  private checkError(error: any) {
    if (CommerceLayerStatic.isApiError(error) || 'errors' in error) {
      if (error.errors[0] && error.errors[0].status && error.errors[0].status == 401) {
        this.cleanup();
        this.init();
      }
    } else if (error && error.toArray) {
      // maybe we can delete this else if
      const items = error.toArray();
      if (items[0] && ['UNAUTHORIZED', 'INVALIDTOKEN'].includes(items[0].code)) {
        this.cleanup();
        this.init();
      }
    }
  }

  public async fetchOrder(id: string) {
    try {
      const order = await this.commerceLayerSdk.orders.retrieve(id, {
        include: [
          'line_items',
          'billing_address',
          'shipping_address',
          'available_payment_methods',
          'shipments.available_shipping_methods',
          'payment_method',
          'payment_source',
          'customer.customer_addresses.address',
        ],
      });
      this.order = order;
      return order;
    } catch (error) {
      this.logError('fetchOrder', error);
      this.checkError(error);
    }
  }

  private async addCustomerToOrder() {
    if (!this.customer) {
      return;
    }
    if (
      this.order &&
      this.order.customer_email &&
      this.order.customer_email === this.customer.email
    ) {
      return;
    }
    if (this.order?.id && this.customer?.id) {
      await this.setOrderCustomer(this.order.id, this.customer.id);
    }
  }

  public async addCustomerEmail(orderId: string, email: string): Promise<Order> {
    try {
      return await this.commerceLayerSdk.orders.update({
        id: orderId,
        customer_email: email,
        //Order refresh for any user-related coupon validity/invalidity
        _refresh: true,
      });
    } catch (error) {
      if (!this.isCouponError(error)) {
        this.logError('addCustomerEmail', error);
        throw error;
      }
      // maybe the coupon is not valid for this user, remove the coupon and retry
      // TODO add a visible alert to the user informing him/her of the removal of the coupon
      try {
        return await this.commerceLayerSdk.orders.update({
          id: orderId,
          customer_email: email,
          coupon_code: null,
          //Order refresh for any user-related coupon validity/invalidity
          _refresh: true,
        });
      } catch (retryError) {
        this.logError('setOrderCustomer retry error', retryError);
        throw retryError;
      }
    }
  }

  private async setOrderCustomer(orderId: string, customerId: string): Promise<Order> {
    const customer = this.commerceLayerSdk.customers.relationship(customerId);
    try {
      return await this.commerceLayerSdk.orders.update({
        id: orderId,
        customer: customer,
        //Order refresh for any user-related coupon validity/invalidity
        _refresh: true,
      });
    } catch (error) {
      if (!this.isCouponError(error)) {
        this.logError('setOrderCustomer error', error);
        throw error;
      }
      // maybe the coupon is not valid for this user, remove the coupon and retry
      // TODO add a visible alert to the user informing him/her of the removal of the coupon
      try {
        return await this.commerceLayerSdk.orders.update({
          id: orderId,
          customer: customer,
          coupon_code: null,
          //Order refresh for any user-related coupon validity/invalidity
          _refresh: true,
        });
      } catch (retryError) {
        this.logError('setOrderCustomer retry error', retryError);
        throw retryError;
      }
    }
  }

  private getHeaders() {
    const headers = {
      ...defaultHeaders,
      Authorization: `Bearer ${this.token?.accessToken}`,
    };
    return headers;
  }

  public async setPaymentMethod(orderId: string, paymentMethodId: string): Promise<Order> {
    try {
      const order = await this.commerceLayerSdk.orders.update({
        id: orderId,
        payment_method: this.commerceLayerSdk.payment_methods.relationship(paymentMethodId),
      });
      return order;
    } catch (error) {
      this.logError('setPaymentMethod error', error);
      throw error;
    }
  }

  public async deletePaymentSource(type: string, id: string) {
    const url = `${this.apiClientUrl}/${type}/${id}`;
    const res = await api.delete(url, { headers: this.getHeaders() }).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error(error.response.data, error.response.status, error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error', error.message);
      }
      console.error(error.config);
    });
    if (res) {
      return res.data;
    }
  }

  public async setPaymentSource(orderId: string, type = 'braintree_payments') {
    const url = `${this.apiClientUrl}/${type}`;
    try {
      const res = await api.post(
        url,
        {
          data: {
            type,
            attributes: {},
            relationships: {
              order: {
                data: {
                  type: 'orders',
                  id: orderId,
                },
              },
            },
          },
        },
        { headers: this.getHeaders() }
      );

      return res.data;
    } catch (error) {
      this.logError('setPaymentSource error', error);
      throw error;
    }
  }

  public async updateBraintreePaymentSource(
    sourceId: string,
    payment_method_nonce: string | null
  ): Promise<BraintreePayment> {
    try {
      const res = await this.commerceLayerSdk.braintree_payments.update({
        id: sourceId,
        payment_method_nonce,
      });
      return res;
    } catch (error) {
      this.logError('updateBraintreePaymentSource error', error);
      throw error;
    }
  }

  public async placeOrder(
    orderId: string,
    shipId: string,
    billId: string,
    metadata = {},
    locale: Locales = 'en'
  ): Promise<Order> {
    try {
      const res = await this.commerceLayerSdk.orders.update({
        id: orderId,
        _place: true,
        language_code: locale,
        metadata: metadata,
        _shipping_address_clone_id: shipId,
        _billing_address_clone_id: billId,
      });
      return res;
    } catch (error) {
      this.logError('placeOrder', error);
      throw error;
    }
  }

  public async addAddressToCustomer(
    customerId: string,
    addressId: string
  ): Promise<CustomerAddress> {
    try {
      // @ts-expect-error no customer_email needed
      const res = this.commerceLayerSdk.customer_addresses.create({
        customer: this.commerceLayerSdk.customers.relationship(customerId),
        address: this.commerceLayerSdk.addresses.relationship(addressId),
      });
      return res;
    } catch (error) {
      this.logError('addAddressToCustomer', error);
    }
  }

  public async setAddresses(attributes: {
    orderId: string;
    billId?: string;
    shipId?: string;
  }): Promise<Order> {
    try {
      const updateObject: OrderUpdate = {
        id: attributes.orderId,
        //_billing_address_clone_id: attributes.billId,
        //_shipping_address_clone_id: attributes.shipId,
      };
      if (attributes.billId) {
        updateObject.billing_address = this.commerceLayerSdk.addresses.relationship(
          attributes.billId
        );
      }
      if (attributes.shipId) {
        updateObject.shipping_address = this.commerceLayerSdk.addresses.relationship(
          attributes.shipId
        );
      }
      const res = await this.commerceLayerSdk.orders.update(updateObject, {
        include: ['billing_address', 'shipping_address'],
      });

      return res;
    } catch (error) {
      this.logError('setAddresses', error);
    }
  }

  public async setShipping(attributes: {
    shipmentId: string;
    shippingMethodId: string;
  }): Promise<Shipment> {
    try {
      const res = await this.commerceLayerSdk.shipments.update({
        id: attributes.shipmentId,
        shipping_method: this.commerceLayerSdk.shipping_methods.relationship(
          attributes.shippingMethodId
        ),
      });
      return res;
    } catch (error) {
      this.logError('setShipping', error);
      throw error;
    }
  }

  public async removeCoupon(orderId: string): Promise<Order> {
    try {
      return await this.commerceLayerSdk.orders.update({
        id: orderId,
        coupon_code: null,
      });
    } catch (error) {
      this.logError('removeCoupon', error);
      throw error;
    }
  }

  public async addCoupon(attributes: { orderId: string; code: string }): Promise<Order> {
    return await this.commerceLayerSdk.orders.update({
      id: attributes.orderId,
      coupon_code: attributes.code,
    });
  }

  public async subscribeToProfilingOrNewsletter(attributes: {
    email: string;
    metadata: { consent_type: string };
  }) {
    const { email, metadata } = attributes;
    try {
      return await this.commerceLayerSdk.customer_subscriptions.create({
        customer_email: email,
        reference: metadata?.consent_type || 'Newsletter',
        metadata: metadata,
      });
    } catch (error: any) {
      if (CommerceLayerStatic.isApiError(error)) {
        if (error.errors[0].status == 422) {
          // ignore error 422 because it is caused by a subscription already made
          //"reference - has already been taken"
          return;
        }
      }
      this.logError('subscribeToProfilingOrNewsletter', error);
    }
  }

  private get headers() {
    return { accept: 'Accept: application/json', 'content-type': 'application/json' };
  }

  public async resetPassword(attributes: { email: string; url: string; language: string }) {
    const url = `${SRV_URL}/resetPassword`;
    const res = await api.post(url, attributes, { headers: this.headers });
    return res.data;
  }

  public async changePassword(attributes: { password: string; id: string; token: string }) {
    try {
      const url = `${SRV_URL}/changePassword`;
      const res = await api.post(url, attributes, { headers: this.headers });
      return res.data;
    } catch (error) {
      this.logError('changePassword', error);
      throw error;
    }
  }

  public async updateCustomer(attributes: Record<string, string>) {
    const customer = this.commerceLayerSdk.customers.update({
      id: this.customer?.id,
      ...attributes,
    });
    return customer;
  }

  public async updateAddress(
    attributes: BillingAddressFormData | Address | AddressFormData,
    addressType: AddressType
  ): Promise<Address> {
    try {
      return await this.commerceLayerSdk.addresses.update({
        id: attributes.id,
        business: !!attributes.company,
        first_name: attributes.first_name,
        last_name: attributes.last_name,
        company: attributes.company,
        line_1: attributes.line_1,
        line_2: attributes.line_2,
        city: attributes.city,
        zip_code: attributes.zip_code,
        state_code: attributes.state_code,
        country_code: attributes.country_code,
        phone: attributes.phone,
        metadata: {
          ...attributes.metadata,
          isShipping: addressType === 'shipping',
          isBilling: addressType === 'billing',
          lastUsage: new Date().getTime(),
        },
      });
    } catch (error) {
      this.logError('updateAddress', error);
      throw error;
    }
  }

  public async createAddress(
    attributes: Address | BillingAddressFormData | AddressFormData,
    addressType: AddressType
  ) {
    try {
      return await this.commerceLayerSdk.addresses.create({
        business: !!attributes.company,
        first_name: attributes.first_name,
        last_name: attributes.last_name,
        company: attributes.company,
        line_1: attributes.line_1,
        line_2: attributes.line_2,
        city: attributes.city,
        zip_code: attributes.zip_code,
        state_code: attributes.state_code,
        country_code: attributes.country_code,
        phone: attributes.phone,
        metadata: {
          ...attributes.metadata,
          isShipping: addressType === 'shipping',
          isBilling: addressType === 'billing',
          lastUsage: new Date().getTime(),
        },
      });
    } catch (error) {
      this.logError('createAddress', error);
      throw error;
    }
  }

  public async updateItemAvailability(
    itemId: string,
    availability: number | undefined
  ): Promise<LineItem> {
    try {
      const res = await this.commerceLayerSdk.line_items.update({
        id: itemId,
        quantity: availability,
        metadata: { availability },
      });
      return res;
    } catch (error) {
      this.logError('updateItemAvailability', error);
      throw error;
    }
  }

  private async updateLineItemQty(id: string, quantity: number): Promise<LineItem> {
    try {
      const res = await this.commerceLayerSdk.line_items.update({
        id: id,
        quantity: quantity,
      });
      return res;
    } catch (error) {
      this.logError('updateLineItemQty', error);
      throw error;
    }
  }

  private async updateLineItemOptionQty(id: string, quantity: number): Promise<LineItemOption> {
    try {
      const res = await this.commerceLayerSdk.line_item_options.update({
        id: id,
        quantity: quantity,
      });
      return res;
    } catch (error) {
      this.logError('updateLineItemOptionQty', error);
      throw error;
    }
  }

  // NEW

  public async getOrder(checkStatus = true): Promise<Order> {
    const cartId = this.getOrderId();
    try {
      let order = undefined;

      if (!cartId) {
        return order;
      }
      try {
        order = await this.fetchOrder(cartId);
      } catch (err) {
        deleteCartIdSession();
      }

      if (!order) {
        return await this.createOrder();
      }
      if (checkStatus) {
        if (order.status !== 'pending' && order.status !== 'draft') {
          return await this.createOrder();
        }
      }
      this.order = order;
      return order;
    } catch (error) {
      this.logError('GET ORDER ERROR', error);
      if (cartId) {
      }
      this.checkError(error);
    }
  }

  private async createOrder(): Promise<Order> {
    try {
      const createObject: OrderCreate = {
        shipping_country_code_lock: this.countryCode,
      };
      const fakeShippingAddress = await this.getFakeShippingAddress();
      if (fakeShippingAddress) {
        createObject.shipping_address = fakeShippingAddress;
      }
      const order = await this.commerceLayerSdk.orders.create(createObject);

      setCartIdSession(order.id);

      this.order = order;
      return order;
    } catch (error) {
      this.logError('createOrder', error);
    }
    throw 'ERROR, CANNOT CREATE AN ORDER';
  }

  private async getFakeShippingAddress() {
    try {
      const address = (addresses as Address[]).find(
        (sc) => sc.country_code.toUpperCase() == this.countryCode.toUpperCase()
      );
      if (address) {
        return await this.commerceLayerSdk.addresses.retrieve(address.id);
      }
    } catch (error) {
      this.logError('getFakeShippingAddress', error);
    }
    return null;
  }

  public async updateLineItemQuantity(
    lineItem: LineItem,
    incOrDecQuantity: number,
    indexGtm: number,
    itemListName: string
  ) {
    const { id, quantity } = lineItem;
    const updatedLineItem = await this.updateLineItemQty(lineItem.id, incOrDecQuantity);
    const findItem = await this.commerceLayerSdk.line_items.retrieve(id, {
      include: ['line_item_options'],
    });
    const options = findItem?.line_item_options;
    if (options && options.length > 0) {
      options.forEach(async (option) => {
        await this.updateLineItemOptionQty(option.id, incOrDecQuantity);
      });
      // force refresh order for webhook shipping and taxes calculator
      await this.refreshOrder(this.order.id);
    }
    //Handle for increment or decrement items from cart page
    if (quantity && quantity >= incOrDecQuantity) {
      trackRemoveFromCart(lineItem, quantity - incOrDecQuantity);
    } else if (quantity) {
      trackAddToCart(lineItem, incOrDecQuantity - quantity, indexGtm, itemListName);
    }
  }

  public getCustomer = () => this.customer;

  public async deleteAddressAndCustomerAddressesById(
    customerAddressesId: string,
    addressId: string
  ) {
    await this.commerceLayerSdk.customer_addresses.delete(customerAddressesId);
    await this.commerceLayerSdk.addresses.delete(addressId);

    await this.verifyOrderConsistency(addressId);
  }

  private verifyOrderConsistency = async (addressId: string) => {
    const orderId = this.getOrderId();
    if (orderId) {
      const order = await this.fetchOrder(orderId);

      const billId =
        order?.billing_address?.id === addressId ? undefined : order?.billing_address?.id;
      const shipId =
        order?.shipping_address?.id === addressId ? undefined : order?.shipping_address?.id;

      if (!billId || !shipId) {
        this.setAddresses({ orderId, billId, shipId });
      }
    }
  };

  public async getAddressList(countryCode: string): Promise<CustomerAddress[]> {
    const customerAddresses: CustomerAddress[] = [];
    if (this.customer) {
      const PAGE_SIZE: QueryPageSize = 25;

      let goOn = true;
      let pageNumber = 0;
      while (goOn) {
        pageNumber += 1;
        const params = {
          include: ['address'],
          pageNumber,
          pageSize: PAGE_SIZE,
          filters: {},
        };
        if (countryCode) {
          params.filters = { address_country_code_eq: countryCode };
        }
        const response = await this.commerceLayerSdk.customer_addresses.list(params);
        if (!response || response?.length < PAGE_SIZE) {
          goOn = false;
        }
        customerAddresses.push(...response!);
      }
      return customerAddresses.filter((customerAddress) => {
        return this.isValidCustomerAddress(customerAddress);
      });
    }
    return customerAddresses;
  }

  public isValidCustomerAddress(customerAddress: CustomerAddress) {
    return customerAddress.address?.metadata?.type !== 'test';
  }

  public async getAddressById(id: string) {
    return await this.commerceLayerSdk.addresses.retrieve(id);
  }

  public upsertAddress = async (
    addressFormData: BillingAddressFormData | AddressFormData,
    addressType: AddressType
  ) => {
    if (addressFormData.id) {
      return await this.updateAddress(addressFormData, addressType);
    } else {
      const address = await this.createAddress(addressFormData, addressType);
      return await this.commerceLayerSdk.customer_addresses.create({
        customer_email: this.customer.email,
        customer: this.commerceLayerSdk.customers.relationship(this.customer.id),
        address: this.commerceLayerSdk.addresses.relationship(address.id),
      });
    }
  };

  private logError(context: string, error: any) {
    if (error instanceof AxiosError) {
      console.error(context, 'AxiosError', error.response ?? error.message);
    } else if (CommerceLayerStatic.isApiError(error)) {
      console.error(context, 'ApiError', error.errors);
    } else if (CommerceLayerStatic.isSdkError(error)) {
      console.error(context, 'SdkError', { message: error.message, name: error.name });
    } else {
      console.error(context, error);
    }
  }

  private isCouponError(error: any): boolean {
    return error?.errors[0]?.detail && error.errors[0].detail.includes('coupon_code');
  }

  public getToken() {
    return this.token;
  }
}
