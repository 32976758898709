import config from '@data/config.json';

export const localStorageSetItem = (key: string, value: string) => {
  const { projectName } = config;
  try {
    localStorage.setItem(`${projectName}${key}`, value);
  } catch (error) {
    console.error('localStorageSetItem', error);
  }
};

export const localStorageGetItem = (key: string) => {
  const { projectName } = config;
  try {
    return localStorage.getItem(`${projectName}${key}`);
  } catch (error) {
    console.error('localStorageGetItem', error);
  }
};

export const localStorageRemoveItem = (key: string) => {
  const { projectName } = config;
  try {
    localStorage.removeItem(`${projectName}${key}`);
  } catch (error) {
    console.error('localStorageRemoveItem', error);
  }
};
